import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import persistStore from "redux-persist/es/persistStore";
import storage from "redux-persist/lib/storage";
import apiSlice from "../api/apiSlice";
import chatReducer from "../chat/chatReducer";
import connectionSlice from "../features/connections/connectionSlice";
import pageTitleSlice from "../features/PageTitle/pageTitleSlice";
import orderSlice from "../features/Service/orderSlice";
import userInfo from "../features/userInfo/userInfo";
import findLawyerKaziAgentFilterSlice from "../features/FindLawyerKaziAgentFilter/findLawyerKaziAgentFilterSlice";
import SwapSlice from "../features/Swap/SwapSlice";

const persistConfig = {
  key: "root",
  version: 1,
  storage,
  whiteList: ["userInfo", "chat", "filter", "checkoutDetailes"],
};

const reducer = combineReducers({
  userInfo: userInfo,
  chat: chatReducer,
  pageTitle: pageTitleSlice,
  ordersInfo: orderSlice,
  connection: connectionSlice,
  findLawyerKaziAgent: findLawyerKaziAgentFilterSlice,
  swap: SwapSlice,
});
const persistedReducer = persistReducer(persistConfig, reducer);
const store = configureStore({
  reducer: {
    [apiSlice.reducerPath]: apiSlice.reducer,
    persistedReducer,
  },
  devTools: process.env.NODE_ENV !== "production",
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(apiSlice.middleware),
});

export const persistor = persistStore(store);

export default store;
