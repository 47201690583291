import React from "react";

// components
import { TopMenu } from "./TopMenu/TopMenu";
import { BottomNav } from "../BottomNav";
import Banner from "../../../../components/pages/Home/Banner/Banner";
import NeedSomethingDone from "../../../../components/pages/Home/NeedSomethingDone/NeedSomethingDone";
import ServicesPromo from "../../../../components/pages/Home/ServicesPromo/ServicesPromo";
import CategoriesPromo from "../../../../components/pages/Home/CategoriesPromo/CategoriesPromo";
import WhyChooseUs from "../../../../components/pages/Home/WhyChooseUs/WhyChooseUs";
import KnowMore from "../../../../components/pages/Home/KnowMore/KnowMore";
import { Services } from "../Services";

const MobileHome = () => {
  return (
    <div className="mov-res">
      <TopMenu />
      <Banner />
      <Services></Services>
      <NeedSomethingDone />
      <WhyChooseUs />
      <ServicesPromo />
      <CategoriesPromo />
      <KnowMore />

      <div className="h-24"></div>
      <BottomNav />
    </div>
  );
};

export default MobileHome;
