import About from "../../../Wrapper/About/About";
import HomePage from "../../../Wrapper/HomePage/HomePage";
import { AboutMov } from "../../../Wrapper/HomePage/mobileversion/about/AboutMov";
import { CommunityMov } from "../../../Wrapper/HomePage/mobileversion/comminuty/CommunityMov";
import { HistoryMov } from "../../../Wrapper/HomePage/mobileversion/history/HistoryMov";
import { MeetMov } from "../../../Wrapper/HomePage/mobileversion/meet/MeetMov";
import { Setting } from "../../../Wrapper/HomePage/mobileversion/setting/Setting";
import { GoogleSignUpInfo } from "../../../components/pages/Authentications/GoogleLogin/GoogleSignUpInfo";
import LoginAsProfessional from "../../../components/pages/Authentications/LoginAsProfessional";
import RegAsProfessional from "../../../components/pages/Authentications/RegAsProfessional";
import Contact from "../../../components/pages/Contact/Contact";
import { OurTeams } from "../../../components/pages/OurTeams/OurTeams";
import { Privacy } from "../../../components/pages/Privacy/Privacy";
import AssociateBlog from "../../../components/pages/Shared/Footer/AssociateBlog/AssociateBlog";
import { CookiePolicy } from "../../../components/pages/Shared/Footer/CookiePolicy/CookiePolicy";
import CustomerReviews from "../../../components/pages/Shared/Footer/CustomerReviews/CustomerReviews";
import EndUserAgreement from "../../../components/pages/Shared/Footer/EndUserAgreement/EndUserAgreement";
import FaqPage from "../../../components/pages/Shared/Footer/FaqPage/FaqPage";
import HelpCenter from "../../../components/pages/Shared/Footer/HelpCenter/HelpCenter";
import QuickStartGuide from "../../../components/pages/Shared/Footer/QuickStartGuide/QuickStartGuide";
import { RefundPolicy } from "../../../components/pages/Shared/Footer/RefundPolicy/RefundPolicy";
import ReportAbuse from "../../../components/pages/Shared/Footer/ReportAbuse/ReportAbuse";
import Tutorials from "../../../components/pages/Shared/Footer/Tutorials/Tutorials";

const lawyerPublicRoutes = [
  {
    id: 1,
    path: "/",
    element: <HomePage />,
  },
  {
    id: 2,
    path: "/login",
    element: <LoginAsProfessional />,
  },
  {
    id: 3,
    path: "/signup",
    element: <RegAsProfessional />,
  },
  {
    id: 4,
    path: "/setting",
    element: <Setting />,
  },
  {
    id: 5,
    path: "/about",
    element: <About />,
  },
  {
    id: 6,
    path: "/contact",
    element: <Contact />,
  },
  {
    id: 7,
    path: "/privacy-policy",
    element: <Privacy />,
  },
  {
    id: 8,
    path: "/aboutmov",
    element: <AboutMov />,
  },
  {
    id: 9,
    path: "/historymov",
    element: <HistoryMov />,
  },
  {
    id: 10,
    path: "/communitymov",
    element: <CommunityMov />,
  },
  {
    id: 11,
    path: "/registration-info",
    element: <GoogleSignUpInfo />,
  },
  {
    id: 12,
    path: "/refundPolicy",
    element: <RefundPolicy />,
  },
  {
    id: 13,
    path: "/faq",
    element: <FaqPage />,
  },
  {
    id: 14,
    path: "/help",
    element: <HelpCenter />,
  },
  {
    id: 15,
    path: "/cookiePolicy",
    element: <CookiePolicy />,
  },
  {
    id: 16,
    path: "/endUserAgreement",
    element: <EndUserAgreement />,
  },
  {
    id: 17,
    path: "/customerReviews",
    element: <CustomerReviews />,
  },
  {
    id: 18,
    path: "/associateBlog",
    element: <AssociateBlog />,
  },
  {
    id: 19,
    path: "/tutorials",
    element: <Tutorials />,
  },
  {
    id: 20,
    path: "/quickStart",
    element: <QuickStartGuide />,
  },
  {
    id: 21,
    path: "/reportAbuse",
    element: <ReportAbuse />,
  },
  {
    id: 22,
    path: "/our-teams",
    element: <OurTeams />,
  },
  {
    id: 23,
    path: "/meetmov",
    element: <MeetMov />,
  },
];

export default lawyerPublicRoutes;
