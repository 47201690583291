import React from "react";
// import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { MobileBackButton } from "../../../../components/shared/Components/MobileBackButton";
import { BottomNav } from "../BottomNav";
import { AccountSettingMov } from "./AccountSettingMov";

export const Setting = () => {
    return (
        <motion.div
            layout
            initial={{
                x: 100,
                opacity: 0,
            }}
            animate={{
                x: 0,
                opacity: 1,
            }}
            exit={{ x: 100, opacity: 0 }}
            transition={{
                opacity: { duration: 0.2 },
                x: { duration: 0.5 },
            }}
            className="max-w-[1024px] mx-auto"
        >
            <MobileBackButton name="Setting" />

            {/* menus  */}
            <AccountSettingMov></AccountSettingMov>
            <div className="h-24"></div>

            {/* bottom navigation  */}
            <BottomNav></BottomNav>
        </motion.div>
    );
};
