// components
import MasterRoute from "./route/MasterRoute";


// css files for swiper.js
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

function App() {
    return <MasterRoute />;
}

export default App;
