import getCookie from "../../../Helper/cookies/getCookie";
import apiSlice from "../../api/apiSlice";

export const packageApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllPackages: builder.query({
      query: () => ({
        url: `/package/professional/get-all?role=lawyer`,
        method: "GET",
        headers: {
          authorization: `Bearer ${getCookie("token")}`,
        },
      }),
    }),

    buyPackage: builder.mutation({
      query: (data) => ({
        url: `/package/professional/buy`,
        method: "POST",
        headers: {
          authorization: `Bearer ${getCookie("token")}`,
        },
        body: data,
      }),
    }),
    buyPackageByPoint: builder.mutation({
      query: (data) => ({
        url: `/package/professional/buy-with-point`,
        method: "POST",
        headers: {
          authorization: `Bearer ${getCookie("token")}`,
        },
        body: data,
      }),
    }),

    buyFreePackage: builder.mutation({
      query: ({ packageId, user, numberOfDay }) => ({
        url: `/package/professional/buy-free?packageId=${packageId}&_user=${user}&numberOfDay=${numberOfDay}`,
        method: "POST",
        headers: {
          authorization: `Bearer ${getCookie("token")}`,
        },
      }),
    }),
  }),
});

export const {
  useGetAllPackagesQuery,
  useBuyPackageMutation,
  useBuyFreePackageMutation,
  useBuyPackageByPointMutation
} = packageApi;
