// configuration
import React from "react";
import { useNavigate } from "react-router-dom";

// Third party package
import { BsFillCheckCircleFill } from "react-icons/bs";

// components
import whyUs from "../../../../assets/images/AboutUs/whyUs.jpg";
import isLoggedIn from "../../../../Helper/hooks/checkLoggerPersestency/isLoggedIn";

const WhyChooseUs = () => {
  const navigate = useNavigate();
  const user = isLoggedIn();

  const list = [
    "Register/Login To Lawyer Feature",
    "Browse Lawyer Profile",
    "Chat Request To 5 Lawyers per Day",
    "Strong and Secured payment system",
    "24/7 Support always",
  ];
  return (
    <div className="bg-[linear-gradient(166deg,rgb(242,40,118)_0%,rgb(148,45,217)_100%)]">
      <div className="max-w-[1200px] mx-auto flex justify-between items-start py-7 md:py-10 lg:py-12 px-6 lg:px-0">
        <div className="flex-1 my-auto">
          <h1 className="text-[27px] md:text-3xl lg:text-5xl font-Poppins font-semibold md:font-medium text-white">
            Why Choose Us?
          </h1>
          <div className="mt-5 md:mt-10">
            {list.map((l, index) => (
              <div key={index} className="flex items-center gap-x-5 mb-3">
                <BsFillCheckCircleFill className="text-secondary text-xl md:text-2xl" />
                <p className="text-slate-50 text-lg md:text-xl font-Inter font-light">
                  {l}
                </p>
              </div>
            ))}
          </div>
          <div className="mt-5 md:mt-10 flex gap-2 md:gap-5">
            <button
              onClick={() => navigate("/login")}
              className={`px-3 md:px-6 py-1 md:py-2 text-white text-base md:text-xl font-fira md:font-medium bg-secondary transition duration-500 hover:bg-opacity-70 border border-secondary hover:border-opacity-70 rounded-[4px] whitespace-nowrap ${
                user && "hidden"
              }`}
            >
              Create Account
            </button>
            <button className="transition duration-500 px-3 md:px-6 py-1 md:py-2 text-white text-base md:text-xl font-fira md:font-medium rounded-[4px] border border-white bg-[linear-gradient(to_bottom,transparent_0%,rgba(255,255,255,.2)_100%)] hover:bg-[linear-gradient(to_bottom,transparent_0%,rgba(255,255,255,.4)_100%)] whitespace-nowrap">
              Earn More Money
            </button>
          </div>
        </div>
        <div className="flex-1 hidden lg:block">
          <img className="rounded-md" src={whyUs} alt="" />
        </div>
      </div>
    </div>
  );
};

export default WhyChooseUs;
