import React, { Suspense } from "react";

// Components
import PackagePromo from "../../components/pages/Home/PackagePromo/PackagePromo";
import Footer from "../../components/shared/Footer/Footer";
import MobileHome from "./mobileversion/MobileHome/MobileHome";
import NavBar from "../../components/shared/NavBar/NavBar";
import Banner from "../../components/pages/Home/Banner/Banner";
import NeedSomethingDone from "../../components/pages/Home/NeedSomethingDone/NeedSomethingDone";
import ServicesPromo from "../../components/pages/Home/ServicesPromo/ServicesPromo";
import CategoriesPromo from "../../components/pages/Home/CategoriesPromo/CategoriesPromo";
import { BrandLoader } from "../../components/shared/Cards/Loader/BrandLoader/BrandLoader";
import KnowMore from "../../components/pages/Home/KnowMore/KnowMore";
import WhyChooseUs from "../../components/pages/Home/WhyChooseUs/WhyChooseUs";

const HomePage = () => {
  return (
    <div>
      <Suspense fallback={<BrandLoader />}>
        <div className="hidden lg:block">
          <NavBar />
          <Banner />
          <NeedSomethingDone />
          <WhyChooseUs />
          <ServicesPromo />
          <CategoriesPromo />
          <PackagePromo />
          <KnowMore />
          <Footer />
        </div>

        {/* Mobile version */}
        <div className="lg:hidden">
          <MobileHome />
        </div>
      </Suspense>
    </div>
  );
};

export default HomePage;
