// Configuration
import React from "react";
import { BrowserRouter, Routes } from "react-router-dom";
import lawyerPrivateRouteRenderer from "./lawyer/privateRoutes/lawyerPrivateRouteRenderer";
import lawyerPublicRouteRenderer from "./lawyer/publicRoutes/lawyerPublicRouteRenderer";
import chatPrivateRouteRenderer from "./lawyer/ChatPrivateRoutes/chatPrivateRouteRenderer";

// Components

const MasterRoute = () => {
  return (
    <BrowserRouter>
      <Routes>
        {/* LAWYER PRIVATE ROUTES */}
        {lawyerPrivateRouteRenderer()}
        {/* LAWYER PUBLIC ROUTES */}
        {lawyerPublicRouteRenderer()}
        {/* Chat Private Routes */}
        {chatPrivateRouteRenderer()}
      </Routes>
    </BrowserRouter>
  );
};

export default MasterRoute;
