import { Route } from "react-router-dom";
import lawyerPublicRoutes from "./lawyerPublicRoutes";

const lawyerPublicRouteRenderer = () => {
    return lawyerPublicRoutes.map(route => {
        return <Route path={route.path} element={route.element} key={route.id} />;
    });
};

export default lawyerPublicRouteRenderer;
