// configuration
import React, { useState } from "react";

// Third party packages
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import { MdKeyboardArrowRight } from "react-icons/md";

// components
import { useGetPercentageOfProfileCompleteQuery } from "../../Redux/features/userInfo/userApi";
import verificationIcon from "../../assets/images/icons/verification.png";
import VarientAnimation from "../../assets/utilities/Animation/VarientAnimation";
import StepperMessageModal from "./StepperMessageModal";

export const VerificationCard = ({ title, mt, height, responsive }) => {
    const [showMessagePopup, setShowMessagePopup] = useState(false);

    const { data } = useGetPercentageOfProfileCompleteQuery();

    let percentage = 0;
    if (data) {
        percentage = data?.data?.percentage;
    }

    return (
        <VarientAnimation direction="right" delay={0.3}>
            <div
                className={`mt-[${mt}] lg:w-full h-[${height}] bg-white shadow-[0px_10px_5px_rgba(119,123,146,0.02)] rounded-[10px] py-[16px] px-[20px]`}
            >
                <section className="flex flex-col   justify-center h-full w-full">
                    {title && (
                        <div className="flex items-center gap-x-[9px]">
                            <img src={verificationIcon} alt="" />
                            <h1 className="text-[#000000] text-[24px] leading-[20px] font-semibold font-fira">Verification Status</h1>
                        </div>
                    )}
                    <Box sx={{ width: "100%" }} className="my-[15px]">
                        <div className="w-full flex justify-between text-[#000000] text-[14px] font-Poppins ">
                            <p className="font-light">Progress</p>
                            <p>{percentage}%</p>
                        </div>
                        <LinearProgress variant="determinate" value={percentage} />
                    </Box>
                    <div className="flex items-center">
                        <button className="text-[#942DD9] text-[16px] font-normal font-fira leading-[20px]" onClick={() => setShowMessagePopup(true)}>
                            Complete Verification
                        </button>
                        <MdKeyboardArrowRight className="text-[#942DD9]" />
                    </div>
                </section>

                {showMessagePopup && <StepperMessageModal {...{ setShowMessagePopup }} />}
            </div>
        </VarientAnimation>
    );
};
