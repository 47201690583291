// configuration
import React, { useEffect } from "react";
import { Outlet } from "react-router";

// Third party package
import { useSelector } from "react-redux";

// components
import CustomHeader from "../../../shared/CustomHeader/CustomHeader";
import { Sidebar } from "../Sidebar/Sidebar";

export const Inbox = () => {
  const res = useSelector((state) => state.persistedReducer.userInfo.userInfo);
  let profile = res?.data ? res.data.user : res?.user;

  // Scroll to top
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  return (
    <div className="bg-[#FAFBFF] pb-[230px]">
      <CustomHeader title="Chat" />
      <section>
        <div className="lg:w-full xl:max-w-[1200px] h-[calc(100vh-30vh)] mx-auto flex mt-[66px]">
          <div className="lg:w-[437px] xl:w-[557px] h-full">
            <Sidebar profile={profile} />
          </div>
          <div className="flex-1 h-[calc(30vh + 66px - 100vh)]">
            <Outlet />
          </div>
        </div>
      </section>
    </div>
  );
};
